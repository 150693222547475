import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        promo
        color
      }
      fields {
        name
      }
    }
  }
`;
export const _frontmatter = {
  "title": "Start a Gratitude Project",
  "date": "2018-05-08",
  "description": "Lift yourself and others with the best kind of side project.",
  "promo": "grids",
  "color": "#25ac5b"
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`There's no such thing as a bad side project. Side projects reinvigorate you, get you excited about tech and life again. `}</p>
    <p>{`There are many different kinds of side projects. Some give you valuable experience that make you better at your job. Some prep you for your next job. Some `}<em parentName="p">{`become`}</em>{` your next job like mine did. But I'm learning that the best kind of side project is a `}<strong parentName="p">{`gratitude project`}</strong>{`.`}</p>
    <h2>{`What's that?`}</h2>
    <p>{`A gratitude project is essentially a project you can get passionate about that `}<strong parentName="p">{`serves others`}</strong>{` instead of yourself. `}</p>
    <p><img parentName="p" {...{
        "src": "/img/project-diagram.jpg",
        "alt": "project diagram"
      }}></img></p>
    <p>{`A side project becomes a passion project when you pick something you are (or can become) genuinely excited about. This is an important piece to get right. It doesn't matter how popular a cause/mission/tech/dream is, if it doesn't speak to you personally. You gotta be real with yourself and pick something that resonates with you.`}</p>
    <p>{`A passion project becomes a gratitude project when the person it serves is someone other than yourself. Where number one takes a back seat for a minute. You do something kind and good for somebody else for a change, expecting absolutely `}<strong parentName="p">{`nothing in return`}</strong>{`.`}</p>
    <h2>{`No Expectations`}</h2>
    <p>{`There's a slippery slope I tend to go skiing down regularly: hope leads to excitement which leads to expectations which lead to disappointment. A gratitude project is a chance to practice explicity setting `}<em parentName="p">{`zero`}</em>{` expectations. You'll inevitably feel awesome by doing it, but that's not the goal. In fact there is no goal - other than to use your skills and creativity to make someone's life a little bit better.`}</p>
    <h2>{`My Gratitude Project`}</h2>
    <p>{`Since quitting my job last year to do my own thing I've experienced a lot of ups and downs. Most of the downs centered around fear. Fear of not being good enough to make it on my own. Fear that people wouldn't love me or the things I made.`}</p>
    <p>{`I have so much gratitude in my heart for every single person who's supported me and purchased my `}<a parentName="p" {...{
        "href": "https://gridcritters.com/"
      }}>{`CSS Grid Mastery Game`}</a>{`. Because of them I've been able to continue my entrepreneur journey and work on more cool things. Just saying `}<em parentName="p">{`thank you`}</em>{` didn't feel adequate.`}</p>
    <p>{`So when `}<a parentName="p" {...{
        "href": "https://twitter.com/MeganCharlesIQ"
      }}>{`Megan Charles`}</a>{` told me she loved the character artwork from my games and wished she could have them as laptop stickers, I simply couldn't say no.`}</p>
    <p>{`I set out to create the absolute coolest stickers ever to grace the back of a laptop. With ambitions like that it's no surprise that I quickly got stuck. You can read the `}<a parentName="p" {...{
        "href": "/post/unstuck"
      }}>{`full story`}</a>{` but basically a honeycomb shaped kids' shoebox I saw at a farm gave me the inspiration I needed.`}</p>
    <p>{`The idea was that the stickers could be arranged however you wanted in a hexagon grid, with stickers taking up either one, two, or three hexagons.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/grid-paper.jpg",
        "alt": "grid paper plan"
      }}></img></p>
    <p>{`Once the designs were done, I happily sent them off to Stickermule for printing. But when the samples came back the stickers didn't snap together like they were supposed to:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/stickergaps.jpg",
        "alt": "sticker gaps"
      }}></img></p>
    <p>{`Their customer support people were super patient with me over the course of our 80 emails back and forth. We made several more samples but couldn't ever get the cuts accurate enough. At one point they told me `}<em parentName="p">{`"Truth be told you have an expert level of attention to detail and some of your questions are new to us"`}</em>{`.`}</p>
    <p>{`Things got a little ridiculous. At one point I found myself proving to them that my calipers were indeed accurate:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/calipers-accurate.jpg",
        "alt": "calipers accurate"
      }}></img></p>
    <p>{`I'm sure they just `}<em parentName="p">{`loved`}</em>{` working with me, but eventually I had to fire them and find a local shop that could produce the quality that I wanted for these.`}</p>
    <p>{`It still took a few more iterations to get it right. I'm used to a feedback loop of `}<em parentName="p">{`seconds`}</em>{` when I code, but with print it took weeks between making adjustments and seeing new print samples. Especially because nobody makes stickers like this. `}</p>
    <p>{`It took way longer than I had planned but I couldn't be happier with how they turned out. All the hard work and patience paid off:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/stickers2.jpg",
        "alt": "geddski stickers"
      }}></img></p>
    <p>{`They look perfect - and snap together perfectly in a hexagon grid, yay!`}<br parentName="p"></br>{`
`}<img parentName="p" {...{
        "src": "/img/stickers4.jpg",
        "alt": "geddski stickers"
      }}></img></p>
    <p>{`Our dinner table transformed into an assembly line as I employed a bit of good ol' fashioned child labor (my kids) to put the packs together.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/stickers3.jpg",
        "alt": "geddski stickers"
      }}></img></p>
    <p>{`It was so fun to finally send out the first batch! It makes me giddy to think of everyone who will come home from work today to find these in the mail. Besides the US, sticker packs are now on their way to Brazil, Australia, Austria, Belgium, Netherlands, England, New Zealand, Mexico, Singapore, Canada, Sweden, Scottland, France, and Spain!`}</p>
    <p><img parentName="p" {...{
        "src": "/img/stickers5.jpg",
        "alt": "geddski stickers"
      }}></img></p>
    <p>{`When I told my people I was sending these to them, I thoroughly enjoyed reading the responses. A handful said `}<em parentName="p">{`"Wow Dave these look amazing! How much do I owe you?"`}</em>{` and I was delighted to be able to say these were on me - just a little something to express my gratitude.`}</p>
    <p>{`The sticker packs costs me about $20 bucks a piece, and I sent each person two packs each (the `}<a parentName="p" {...{
        "href": "https://flexboxzombies.com/"
      }}>{`Flexbox Zombies`}</a>{` pack and the `}<a parentName="p" {...{
        "href": "https://gridcritters.com/"
      }}>{`Grid Critters`}</a>{` pack). But the cost doesn't matter. That's what's awesome about a gratitude project. You get to show people that they mean something to you. You get to warm their hearts and make their day. That's worth more than any reward in the world. It might not be good `}<em parentName="p">{`business`}</em>{`, but hey - it's good `}<em parentName="p">{`humaning`}</em>{`.`}</p>
    <h2>{`Start a Gratitude Project`}</h2>
    <p>{`You can start your own gratitude project. It doesn't have to be anything fancy or cost a ton. You have a lot to be grateful for, and a lot you can give back. What's the first person/thing that comes to mind? How can you serve? Maybe you do something nice for the maintainers of an open source project you love to use. Maybe you volunteer to build a website for a charity group or other good cause. It doesn't even have to be in tech. Maybe you start raising honeybees and donate the honey produced. Whatever it is, your gratitude project is a way that you can be a blessing to people around you, and perhaps find a little bit of joy in the process. I hope you start planning your gratitude project tonight! Let me know what you decide to do!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      